import "./src/tail.css";
// import "semantic-ui-css/semantic.min.css";
import wrapWithProvider from "./wrap-with-provider";

import { Amplify } from "aws-amplify";
import config from "./src/aws-exports";

Amplify.configure(config);

export const wrapRootElement = wrapWithProvider;
