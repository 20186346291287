/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUsersFunction = /* GraphQL */ `
  query ListUsersFunction($type: String, $values: String) {
    listUsersFunction(type: $type, values: $values)
  }
`;
export const getGuides = /* GraphQL */ `
  query GetGuides($id: ID!) {
    getGuides(id: $id) {
      id
      type
      name
      title
      text
      description
      data
      data2
      data3
      createdAt
      updatedAt
    }
  }
`;
export const listGuides = /* GraphQL */ `
  query ListGuides(
    $filter: ModelGuidesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGuides(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        title
        text
        description
        data
        data2
        data3
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStats = /* GraphQL */ `
  query GetStats($id: ID!) {
    getStats(id: $id) {
      id
      object_id
      action
      metric
      createdAt
      updatedAt
    }
  }
`;
export const listStats = /* GraphQL */ `
  query ListStats(
    $filter: ModelStatsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        object_id
        action
        metric
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      staff_member
      form_data
      createdAt
      updatedAt
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        staff_member
        form_data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPractice = /* GraphQL */ `
  query GetPractice($id: ID!) {
    getPractice(id: $id) {
      id
      name
      live
      createdAt
      updatedAt
    }
  }
`;
export const listPractices = /* GraphQL */ `
  query ListPractices(
    $filter: ModelPracticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPractices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        live
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFlow = /* GraphQL */ `
  query GetFlow($id: ID!) {
    getFlow(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listFlows = /* GraphQL */ `
  query ListFlows(
    $filter: ModelFlowFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlows(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($id: ID!) {
    getAudit(id: $id) {
      id
      name
      practice_id
      flow_id
      hash
      createdAt
      updatedAt
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        practice_id
        flow_id
        hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      type
      flow_id
      name
      options_array
      include_other_option
      text
      index_number
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        flow_id
        name
        options_array
        include_other_option
        text
        index_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      name
      type
      createdAt
      updatedAt
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSubmission = /* GraphQL */ `
  query GetSubmission($id: ID!) {
    getSubmission(id: $id) {
      id
      audit_id
      flow_id
      submissions_array
      submissions_array2
      user
      createdAt
      updatedAt
    }
  }
`;
export const listSubmissions = /* GraphQL */ `
  query ListSubmissions(
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        audit_id
        flow_id
        submissions_array
        submissions_array2
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      user_id
      access
      practice
      test
      me
      again
      too
      much
      b12
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user_id
        access
        practice
        test
        me
        again
        too
        much
        b12
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const auditsByPracticeId = /* GraphQL */ `
  query AuditsByPracticeId(
    $practice_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AuditsByPracticeId(
      practice_id: $practice_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        practice_id
        flow_id
        hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const auditsByHash = /* GraphQL */ `
  query AuditsByHash(
    $hash: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AuditsByHash(
      hash: $hash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        practice_id
        flow_id
        hash
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const questionsByFlowId = /* GraphQL */ `
  query QuestionsByFlowId(
    $flow_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    QuestionsByFlowId(
      flow_id: $flow_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        flow_id
        name
        options_array
        include_other_option
        text
        index_number
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const submissionsByAuditId = /* GraphQL */ `
  query SubmissionsByAuditId(
    $audit_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    SubmissionsByAuditId(
      audit_id: $audit_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        audit_id
        flow_id
        submissions_array
        submissions_array2
        user
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
